export const AWS_CONFIG = {
    Auth: {
        region: 'us-west-2',
        userPoolId: 'us-west-2_gG6IXKyhX',
        userPoolWebClientId: '66474s6s0r49t5psclfirdb9vt',
    }
};

export const CONFIG = {
    baseApiUrl: 'https://dev.api.aldoforms.com/admin-api'
};

export const ALDO_PWA_APP_URL = 'https://dev.aldoforms.com';
